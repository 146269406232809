<template>
  <div ref="tick" class="tick">
    <div data-repeat="true" aria-hidden="true">
      <span data-view="flip"></span>
    </div>
  </div>
</template>

<script>
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.css";

export default {
  name: "Flip",
  props: ["value"],
  watch: {
    value(newValue) {
      this._tick.value = newValue;
    },
  },
  mounted() {
    this._tick = Tick.DOM.create(this.$refs.tick, {
      value: this.$options.propsData.value,
    });
  },
  destroyed() {
    Tick.DOM.destroy(this.$refs.tick);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* small screen */

@media only screen and (max-width: 600px) {
  .tick {
    font-size: 2em;
  }
}

/* large screen */
@media only screen and (min-width: 600px) {
  .tick {
    font-size: 4em;
  }
}
</style>
