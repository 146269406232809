<template>
  <div>
    <v-dialog
      v-model="privacy_policy_dialog"
      no-click-animation
      :overlay-opacity="0.8"
      :overlay="true"
      transition="dialog-transition"
      max-width="600"
      persistent
    >
      <v-card>
        <privacyPolicyText />
        <v-card-actions>
          <v-spacer />
          <v-btn text width="150" @click="privacy_policy_dialog = false">{{
            $t("btn_close")
          }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import privacyPolicyText from "./privacyPolicy_text.vue";

export default {
  props: {},
  components: {
    privacyPolicyText,
  },
  data: () => ({
    privacy_policy_dialog: false,
  }),
  methods: {},
  mounted() {
    EventBus.$on("open_privacy_policy", () => {
      console.log("open_cookie");
      this.privacy_policy_dialog = true;
    });
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>